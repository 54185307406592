
import i18n from '@/vueI18n';
export default {
  setup() {},
  props: ['clinic'],
  computed: {
    isDark() {
      return this.$q.dark.isActive;
    },
    isRTL() {
      return i18n.locale == 'ar';
    },
  },
};
